export const sendFormEvent = (formType, action) => {
  console.log(`Sending GA4 event: ${formType}_${action}`);
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", `${formType}_${action}`, {
      event_category: "Forms",
      event_label: formType,
    });
  }
};

export const sendLeadGenerationEvent = (formType) => {
  console.log(`Sending GA4 event: Lead confirmed from ${formType}`);
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", `GENERATED_LEAD`, {
      event_category: "Lead Generation",
      event_label: formType,
    });
  }
};

export const sendDownloadEvent = (fileType, fileName) => {
  console.log(`Sending GA4 download event: ${fileType}_${fileName}`);
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", `MANUAL_DOWNLOAD`, {
      event_category: "Download",
      event_label: fileName,
      file_type: fileType,
    });
  }
};
